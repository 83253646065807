<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title color="white">Dettaglio progetto</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <div class="richiesta">
          <div class="field field_title">
            <div class="title project_title">
              {{ progetto.projects_name }}
            </div>
          </div>
          <div class="field">
            <div class="title">Cliente</div>
            <div class="value">
              {{ riferimentoCliente(progetto) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Telefono</div>
            <div class="value">
              <span v-if="progetto.customers_phone">
                <a :href="`tel:${progetto.customers_phone}`" class="mobile_link">{{ progetto.customers_phone }}</a>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="field">
            <div class="title">Stato</div>
            <div class="value">
              <div class="stato_progetto">
                <span class="badge" :class="statoProgetto(progetto.projects_status)">
                  {{ progetto.projects_status_value }}
                </span>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="title">Data inizio</div>
            <div class="value">
              {{ dateFormat(progetto.projects_start_date) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Scadenza</div>
            <div class="value">
              {{ dateFormat(progetto.projects_deadline) }}
            </div>
          </div>
          <div class="field">
            <div class="title">Ore stimate</div>
            <div class="value">
              {{ progetto.projects_estimated_hours }}
            </div>
          </div>
          <div class="field">
            <div class="title">Priorità</div>
            <div class="badge" :class="prioritaProgetto(progetto.projects_priority)">
              {{ progetto.projects_priority_value ? progetto.projects_priority_value : "-" }}
            </div>
          </div>
          <!--           <div class="field">
            <div class="title">Priorità</div>
            <div class="badge" :class="prioritaTicket(ticket.tickets_priority)">
              {{ ticket.tickets_priority_value ? ticket.tickets_priority_value : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Stato</div>
            <div class="badge" :class="statoTicket(ticket.tickets_status)">
              {{ ticket.tickets_status_value ? ticket.tickets_status_value : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Creato da</div>
            <div class="value">
              {{ ticket.users_first_name && ticket.users_last_name ? `${ticket.users_first_name} ${ticket.users_last_name}` : "-" }}
            </div>
          </div>
          <div v-if="ticket.tickets_attachments" class="field_allegati">
            <div class="title">Allegati</div>
            <div class="allegati_container">
              <img v-for="(image, index) in JSON.parse(ticket.tickets_attachments)" :key="index" :src="setImageUrl(image)" alt="" />
            </div>
          </div>
          <div class="field_note">
            <div class="title">Descrizione</div>
            <div class="value">
              {{ parseHtmlEnteties(ticket.tickets_message) }}
            </div>
          </div> -->
        </div>

        <!--         <div v-if="ticket.tickets_status != 5" class="nuovo_intervento" style="display:none">
          <div class="intervento_button" @click="openNuovoIntervento">
            Prendi il ticket in carico
          </div>
        </div>
        <div v-if="ticket.tickets_status != 5" class="nuovo_intervento">
          <div class="intervento_button" @click="openNuovoIntervento">
            Crea intervento
          </div>
        </div> -->

        <div class="data_switcher">
          <div class="type" :class="{ active: showInterventi }" @click="setActiveData('interventi')">Interventi</div>
          <div class="type" :class="{ active: showTickets }" @click="setActiveData('ticket')">Ticket</div>
        </div>

        <div v-if="showInterventi" class="interventi_container">
          <!-- <div class="interventi_label">Interventi</div> -->
          <div v-if="loadingInterventi" class="loading_spinner">
            <ion-spinner name="crescent" color="dark"></ion-spinner>
          </div>
          <div v-else>
            <div v-if="interventi.length == 0">
              <div class="no_interventi_container">
                <div class="no_interventi">
                  Nessun intervento effettuato
                </div>
              </div>
            </div>
            <div v-else v-for="intervento in interventi" :key="intervento.tickets_reports_id" class="intervento" @click="openInterventoDetail(intervento)">
              <div class="date">
                {{ dateFormat(intervento.tickets_reports_date) }},
                {{ intervento.tickets_reports_start_time + " - " + intervento.tickets_reports_end_time }}
              </div>
              <div class="badge" :class="statusIntervento(intervento.tickets_reports_stato_lavori)">
                {{ intervento.tickets_reports_stato_lavori_value }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="showTickets" class="tickets_container">
          <!-- <div class="tickets_label">Tickets</div> -->
          <div v-if="loadingTickets" class="loading_spinner">
            <ion-spinner name="crescent" color="dark"></ion-spinner>
          </div>
          <div v-else>
            <div v-if="tickets.length == 0">
              <div class="no_tickets_container">
                <div class="no_tickets">
                  Nessun ticket da visualizzare
                </div>
              </div>
            </div>
            <div v-else v-for="ticket in tickets" :key="ticket.tickets_reports_id" class="ticket" @click="openTicketDetail(ticket)">
              <div class="info">
                <div class="title">#{{ ticket.tickets_id }} - {{ ticket.tickets_subject }}</div>
                <div class="date">
                  {{ dateFormatWithMinutes(ticket.tickets_creation_date) }}
                </div>
              </div>
              <div class="status">
                <div class="badge" :class="statoTicket(ticket.tickets_status)">
                  {{ ticket.tickets_status_value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  //IonBackButton,
  IonTitle,
  IonContent,
  IonIcon,
  IonButton,
  IonSpinner,
  modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes } from "../services/utils";
import { openToast } from "../services/toast";

import interventiAPI from "../services/interventi";
import ticketAPI from "../services/tickets";

import ModalNuovoInterventoFromTicket from "./ModalNuovoInterventoFromTicket";
import InterventoDetail from "./InterventoDetail";
import TicketDetail from "./TicketDetail";

export default defineComponent({
  name: "ProjectDetail",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonSpinner,
  },
  setup(props, context) {
    const route = useRoute();
    const id = route.params.id; //richiesta_id by route params
    const router = useRouter();

    const progetto = ref([]);

    //console.log(props.data);
    progetto.value = { ...props.data };
    //console.log(progetto.value);

    /**
     * ! Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    const showInterventi = ref(true);
    const showTickets = ref(false);
    /**
     * ! Switcher to show / hide interventi or ticket
     */
    function setActiveData(type) {
      if (type === "interventi") {
        showInterventi.value = true;
        showTickets.value = false;
      } else {
        showTickets.value = true;
        showInterventi.value = false;
      }
    }

    /**
     * ! Limit customers to 35 charactes
     */
    function setShortCustomer(customer) {
      if (!customer) {
        return "-";
      } else {
        const trimmedString = customer.length > 35 ? customer.substring(0, 32) + "..." : customer;
        return trimmedString;
      }
    }

    /**
     * ! Limit lead email to 35 charactes
     */
    function setShortEmail(email) {
      if (!email) {
        return "-";
      } else {
        const trimmedString = email.length > 30 ? email.substring(0, 27) + "..." : email;
        return trimmedString;
      }
    }

    function setImageUrl(img) {
      //console.log(img);
      if (img) {
        return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
      }
    }

    /**
     * ! Remove html tag from ticket description
     */
    function parseHtmlEnteties(str) {
      if (str) {
        const strippedString = str.replace(/(<([^>]+)>)/gi, "");
        return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
          const num = parseInt(numStr, 10); // read num as normal number
          return String.fromCharCode(num);
        });
      } else {
        return "-";
      }
    }

    //Set correct background for ticket status
    const statoTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //ticket aperti
          className = "badge_open";
        } else if (statusId == 2) {
          //ticket in lavorazione
          className = "badge_working";
        } else if (statusId == 3) {
          //ticket attesa risposta
          className = "badge_waiting_answer";
        } else if (statusId == 4) {
          //ticket standby
          className = "badge_standy";
        } else if (statusId == 5) {
          //tickt chiusi
          className = "badge_close";
        } else if (statusId == 5) {
          //tickt cancellati
          className = "badge_canceled";
        }
        return className;
      };
    });

    //Set correct background for ticket priority
    const prioritaTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //ticket aperti
          className = "badge_low";
        } else if (statusId == 2) {
          //ticket in lavorazione
          className = "badge_medium";
        } else if (statusId == 3) {
          //ticket attesa risposta
          className = "badge_high";
        }
        return className;
      };
    });

    //Set correct background for intervento status
    const statusIntervento = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //ticket aperti
          className = "badge_low";
        } else if (statusId == 2) {
          //ticket in lavorazione
          className = "badge_high";
        }
        return className;
      };
    });

    /**
     * ! Badge stato progetto
     */
    const statoProgetto = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //da schedulare
          className = "badge_open";
        } else if (statusId == 2) {
          //in corso
          className = "badge_in_progress";
        } else if (statusId == 3) {
          //in pausa
          className = "badge_pause";
        } else if (statusId == 4) {
          //cancellato
          className = "badge_canceled";
        } else if (statusId == 5) {
          //completato
          className = "badge_completed";
        }
        return className;
      };
    });

    /**
     * ! Badge priorità progetto
     */
    const prioritaProgetto = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //ticket aperti
          className = "badge_low";
        } else if (statusId == 2) {
          //ticket in lavorazione
          className = "badge_medium";
        } else if (statusId == 3) {
          //ticket attesa risposta
          className = "badge_high";
        }
        return className;
      };
    });

    /**
     * ! Print correte customer info
     */
    const riferimentoCliente = (cliente) => {
      if (cliente.customers_company) {
        const trimmedString = cliente.customers_company.length > 40 ? cliente.customers_company.substring(0, 37) + "..." : cliente.customers_company;
        return trimmedString;
      } else if (cliente.customers_name && !cliente.customers_last_name) {
        const trimmedString = cliente.customers_name.length > 40 ? cliente.customers_name.substring(0, 37) + "..." : cliente.customers_name;
        return trimmedString;
      }
      const ref = `${cliente.customers_name} ${cliente.customers_last_name}`;
      const trimmedString = ref.length > 40 ? ref.substring(0, 37) + "..." : ref;
      return trimmedString;
    };

    /**
     * ! Get related interventi
     */
    const interventi = ref([]);
    const loadingInterventi = ref(false);

    const loadProjectInterventi = async () => {
      loadingInterventi.value = true;
      try {
        const res = await interventiAPI.getProjectInterventi(progetto.value.projects_id);
        if (res.status === 0) {
          interventi.value = res.data;
        } else {
          openToast("Errore durante la richiesta degli interventi", "toast_danger");
        }
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta degli interventi", "toast_danger");
      } finally {
        loadingInterventi.value = false;
      }
    };

    /**
     * ! Get related tickets
     */
    const tickets = ref([]);
    const loadingTickets = ref(false);

    const loadProjectTickets = async () => {
      loadingTickets.value = true;
      try {
        const res = await ticketAPI.getProjectTickets(progetto.value.projects_id);
        if (res.status === 0) {
          tickets.value = res.data;
        } else {
          openToast("Errore durante la richiesta dei ticket", "toast_danger");
        }
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta dei ticket", "toast_danger");
      } finally {
        loadingTickets.value = false;
      }
    };

    /**
     * ! Open modal to create new intervento
     */
    async function openNuovoIntervento() {
      const modal = await modalController.create({
        component: ModalNuovoInterventoFromTicket,
        componentProps: {
          data: progetto,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          interventi.value.unshift(detail.data);
          openToast("Intervento salvato con successo", "toast_success");
        }
      });
      return modal.present();
    }

    /**
     * ! Open modal to view intervento details
     */
    async function openInterventoDetail(intervento) {
      const modal = await modalController.create({
        component: InterventoDetail,
        componentProps: {
          data: intervento,
        },
      });
      return modal.present();
    }

    /**
     * ! Open modal to view ticket details
     */
    async function openTicketDetail(ticket) {
      const modal = await modalController.create({
        component: TicketDetail,
        componentProps: {
          data: ticket,
        },
      });
      return modal.present();
    }

    onMounted(() => {
      loadProjectTickets();
      loadProjectInterventi();
    });

    return {
      id,
      loadingInterventi,
      statoTicket,
      dateFormat,
      dateFormatWithMinutes,
      progetto,
      arrowBackOutline,
      closeModal,
      parseHtmlEnteties,
      prioritaTicket,
      setShortCustomer,
      openNuovoIntervento,
      interventi,
      statusIntervento,
      openInterventoDetail,
      setImageUrl,
      tickets,
      openTicketDetail,
      loadingTickets,
      //New field
      riferimentoCliente,
      statoProgetto,
      prioritaProgetto,
      showInterventi,
      showTickets,
      setActiveData,
      setShortEmail,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
.loading_spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}

.description_title {
  color: var(--ion-color-primary, #3880ff) !important;
  margin: 10px 0;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative; /* to be used for new richiesta btn */
  padding: 16px;
  min-height: 100%;
  background: #f8fafc;
}

.richiesta {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e8f0;
}
.richiesta .field:last-of-type {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
  border-bottom: 0;
  margin-bottom: 0px;
}
.richiesta .field.field_title {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 6px;
  margin-bottom: 0px;
  border-bottom: none;
}

.richiesta .field_allegati {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px;
}
.field .title,
.field_allegati .title,
.field_note .title {
  font-size: 14px;
  font-weight: 500;
  color: #475569;
}
.field .project_title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #475569;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.field_allegati .title,
.field_note .title {
  margin-bottom: 8px;
}
.field .value,
.field_allegati .value,
.field_note .value {
  font-size: 14px;
}

.value .badge {
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
}
.badge {
  font-size: 10px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
}
.badge_close,
.badge_low {
  background-color: rgb(220 252 231);
  color: rgb(34 197 94);
  font-weight: bold;
}
.badge_standy,
.badge_high {
  background-color: rgb(254 226 226);
  color: rgb(239 68 68);
  font-weight: bold;
}
.badge_working,
.badge_medium {
  background-color: rgb(255 237 213);
  color: rgb(249 115 22);
  font-weight: bold;
}
.badge_waiting_answer {
  background-color: rgb(237 233 254);
  color: rgb(139 92 246);
  font-weight: bold;
}
.badge_open {
  background-color: rgb(207 250 254);
  color: rgb(6 182 212);
  font-weight: bold;
}
.badge_canceled {
  background-color: rgb(243 244 246);
  color: rgb(31 41 55);
  font-weight: bold;
}

.nuovo_intervento {
  width: 100%;
  margin-top: 16px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
}
.intervento_button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  background-color: #086fa3;
  color: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container,
.tickets_container {
  margin-top: 16px;
}
.interventi_container .interventi_label,
.tickets_container .tickets_label {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #475569;
}
.intervento,
.ticket {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .date {
  font-size: 14px;
  font-weight: 500;
  color: #475569;
}
.intervento .status,
.ticket .status {
  font-size: 14px;
}

.ticket .title {
  font-size: 14px;
  font-weight: 500;
  color: #475569;
  margin-bottom: 4px;
}

.ticket .date {
  font-size: 12px;
  color: #475569;
}

.allegati_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
}
.allegati_container img {
  width: 200px;
}

.no_interventi_container {
  width: 100%;
  /*padding: 16px;*/
}

.no_interventi {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: rgb(226 232 240);
  color: rgb(148 163 184);
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
}

.no_tickets_container {
  width: 100%;
  /*padding: 16px;*/
}

.no_tickets {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: rgb(226 232 240);
  color: rgb(148 163 184);
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
}

/**
* Info progetto
*/
.stato_progetto .badge {
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
}

.stato_progetto .badge_open {
  background-color: rgb(207 250 254);
  color: rgb(6 182 212);
  font-weight: bold;
}
.stato_progetto .badge_in_progress {
  background-color: rgb(255 237 213);
  color: rgb(249 115 22);
  font-weight: bold;
}
.stato_progetto .badge_pause {
  background-color: rgb(237 233 254);
  color: rgb(139 92 246);
  font-weight: bold;
}
.stato_progetto .badge_canceled {
  color: rgb(243 244 246);
  background-color: rgb(31 41 55);
  font-weight: bold;
}
.stato_progetto .badge_completed {
  background-color: rgb(220 252 231);
  color: rgb(34 197 94);
  font-weight: bold;
}

/**
* Switcher Interventi e Ticket
*/
.data_switcher {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data_switcher .type {
  width: 48%;
  color: #475569;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  padding: 8px 4px;
  border-radius: 20px;
  text-align: center;
}
.data_switcher .type.active {
  background-color: #086fa3;
  color: #ffffff;
}

.mobile_link {
  color: #086fa3;
  text-decoration: none;
}

ion-button {
  --color: #ffffff;
}
</style>
